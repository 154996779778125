/*
* 업무구분: 영업
* 화 면 명: MSPFS314M
* 화면설명: 은행송금2010
* 작 성 일: 2023.01.16
* 작 성 자: 이지수
* 수정일 : 2024-06-18 강현철 사유 : ASR240600395 고위험승인내역 화면 개선 요청
* 수정일 : 2024-09-24 강현철 사유 : ASR240701139 AML 및 고객확인 체크보완
*/
<template>
  <ur-page-container class="fts" :show-title="false" title="은행송금" :topButton="true">
    <!-- alert modal popup -->
    <fs-alert-popup ref="alertPopup" :popupObj="pAlertPopupObj"></fs-alert-popup>
    <!-- header start -->
    <fs-header ref="fsHeader" :propObj="pHeaderObj"></fs-header>
    <!-- header end -->

    <!-- .fts-main -->
    <main class="fts-main">
      <ur-box-container direction="column" alignV="start">
        <div class="input-top">
          <div class="column w100p gap12">
            <div class="row w100p">
              <div class="left mr0 gap40 w100p">
                <div class="wrap-input row">
                  <label class="emphasis"> 계좌번호</label>
                  <mo-decimal-field
                    numeric
                    v-model="lv_ed_accn_no.value"
                    :class="lv_ed_accn_no.error ? 'error' : ''"
                    ref="ed_accn_no"
                    class="w130 input-account"
                    @keyup="fn_SearchValid($event)"
                    @paste="fn_SearchValid($event, 'P')"
                    mask="#######-###"
                  />
                  <mo-dropdown ref="ed_acct_gb" :items="lv_acct_list" v-model="lv_ed_acct_gb.value" placeholder=" " class="w340" @input="fn_Acct_no_call($event)" />
                  <mo-text-field v-model="lv_ed_cust_nm.value" class="w80" disabled />
                </div>
                <div class="wrap-input row">
                  <label class="emphasis"> 비밀번호 </label>

                  <m-trans-key-input
                    v-if="isMobile && isMtrans"
                    ref="passwd"
                    class="w80"
                    v-model="passwd.value"
                    type="numberMax4"
                    dialog="Y"
                    start="0"
                    end="-1"
                    :isClear="lv_isClear"
                    @masked-txt="fn_SetMaskedTxt"
                  />

                  <mo-text-field v-else maxlength="4" v-model="passwd.value" :disabled="passwd.disabled" @keyup="fn_pwdInputCallBack" ref="passwd" type="password" class="w80" />

                  <mo-button primary @click="fn_search_fss" v-show="lv_cust_fss.fssInfoExpsrEvntNo != '' && lv_cust_fss.fssInfoExpsrEvntNo != undefined"> 정보노출고객 </mo-button>
                </div>
              </div>
            </div>
            <div class="row w100p">
              <div class="left mr0 gap40">
                <div class="wrap-input row flex-unset">
                  <label class="emphasis"> 유선구분 </label>
                  <mo-radio-wrapper :items="rdo_ars_gb" v-model="tel_use_yn" disabled class="row" />
                </div>
                <div class="wrap-input row">
                  <label class="emphasis"> 대리인여부 </label>
                  <mo-radio-wrapper :items="rdo_agnt_chk_yn" v-model="agnt_gb.value" :disabled="agnt_gb.disabled" class="row" @input="fn_AgentCheck" />
                </div>
              </div>
              <div class="right w100p">
                <div class="wrap-button row">
                  <mo-button @click="fn_init"> 초기화 </mo-button>
                  <mo-button primary @click="fn_search" ref="btn_search"> 조회 </mo-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <!-- Accordion 아코디언 -->
        <!-- fs-cust-info start -->
        <fs-cust-info ref="fsCustInfo" :propObj="pCustInfoObj" @fs-cust-name="fn_SetEdCustName"></fs-cust-info>
        <!-- fs-cust-info end -->
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title flex-sb mr0">출금사항</h2>
        </div>
        <div class="wrap-table accordion accordion01">
          <table class="table w50p th-wsN col2030">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span class="emphasis"> 환매구분</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown class="input-long" :items="lv_commCode.code322" v-model="redem_gb.value" :disabled="redem_gb.disabled" @input="fn_Rad_gubun_chg($event)" placeholder="선택하세요" />
                  </div>
                </td>
                <th>
                  <span class="emphasis"> 금액(좌수)</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field
                      numeric
                      class="input-long"
                      mask="number"
                      v-model="mae_paym_amt.value"
                      ref="mae_paym_amt"
                      :disabled="mae_paym_amt.disabled"
                      @keyup.enter="fn_mae_paym_amt_onchanged($event)"
                      @blur="fn_mae_paym_amt_onchanged($event)"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title flex-sb mr0">출금가능금액</h2>
        </div>
        <div class="wrap-table accordion accordion01">
          <table class="table w50p th-wsN col2030">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span> 전액 출금가능금액</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field numeric class="input-long" mask="number" v-model="paym_possamt.value" :disabled="paym_possamt.disabled" />
                  </div>
                </td>
                <th>
                  <span> 전액 출금가능좌수</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" mask="number" v-model="paym_possqty.value" :disabled="paym_possqty.disabled" />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 연금환매 미이체금액</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <span>전</span> <mo-text-field class="input-long mr10" mask="number" v-model="mae_pension_not_paym_tot_amt" disabled />
                    <!-- class명 "mr10" 추가 2023-04-11 jy -->
                    <span>후</span> <mo-text-field class="input-long" mask="number" v-model="mae_pension_not_paym_pay_amt" disabled />
                  </div>
                </td>
                <th>
                  <span> 출금금액</span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field numeric class="input-long" mask="number" v-model="mae_part_paym_possamt.value" :disabled="mae_part_paym_possamt.disabled" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container direction="column" alignV="start">
        <div class="wrap-table-title">
          <h2 class="table-title flex-sb mr0">출금금액</h2>
        </div>
        <div class="wrap-table accordion accordion01">
          <table class="table w50p th-wsN col2030">
            <!-- <colgroup>
              <col width="20%">
              <col width="30%">
              <col width="20%">
              <col width="30%">
            </colgroup> -->
            <tbody>
              <tr>
                <th>
                  <span> 송금금액 </span>
                </th>
                <td>
                  <div class="wrap-input row">
                    <mo-decimal-field numeric class="input-long" mask="number" v-model="mae_btrans_amt.value" :disabled="mae_btrans_amt.disabled" />
                  </div>
                </td>
                <th class="no-border-left"></th>
                <td></td>
              </tr>
              <tr>
                <th>
                  <span>약정 계좌번호</span>
                </th>
                <td colspan="3">
                  <div class="wrap-input row flex-sb">
                    <span class="row gap6">
                      <mo-dropdown class="w150" :items="lv_commCode.code989" v-model="bank_cd.value" :disabled="bank_cd.disabled" ref="bank_cd" placeholder=" " />
                      <mo-dropdown class="w306" :items="cbo_bank_acct_no" v-model="bank_acct_no.value" :disabled="bank_acct_no.disabled" @input="fn_cbo_btrans_acct_no_onchanged" placeholder=" " />
                    </span>
                    <span><mo-text-field class="w150" v-model="org_tel_no" disabled/></span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </ur-box-container>

      <ur-box-container>
        <div class="wrap-button button-page-bottom row">
          <mo-button primary size="large" @click="fn_confirm" :disabled="btn_confirm"> 확인 </mo-button>
        </div>
      </ur-box-container>
    </main>
    <!-- //.fts-main -->

    <!-- popup313 -->
    <msp-fs-313p ref="popup313" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup313Obj"></msp-fs-313p>

    <!-- popup320 -->

    <msp-fs-320p ref="popup320" @fs-alert-popup="fn_AlertPopup" @fs-popup-callback="fn_Popup_CallBack320" :popupObj="pPopup320Obj"></msp-fs-320p>

    <!-- popup301 -->
    <msp-fs-301p ref="popup301" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup301Obj"></msp-fs-301p>

    <!-- popup305 -->
    <msp-fs-305p ref="popup305" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup305Obj"></msp-fs-305p>

    <!-- popup315 -->
    <msp-fs-315p ref="popup315" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup315Obj"></msp-fs-315p>

    <!-- popup324 -->
    <msp-fs-324p ref="popup324" @fs-popup-callback="fn_Popup_CallBack" :popupObj="pPopup324Obj"></msp-fs-324p>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import FSCommUtil from '~/src/ui/fs/comm/FSCommUtil'
import FSServiceUtil from '~/src/ui/fs/comm/FSServiceUtil'
import FSCodeUtil from '~/src/ui/fs/comm/FSCodeUtil'
import FSInfoUtil from '~/src/ui/fs/comm/FSInfoUtil'
import FSMessageUtil from '~/src/ui/fs/comm/FSMessageUtil'
import FSHeader from '~/src/ui/fs/comm/FSHeader' // header 영역 (공통)
import FSAlertPopup from '~/src/ui/fs/comm/FSAlertPopup' // Alert 팝업 (공통)
import FSCustInfo from '~/src/ui/fs/comm/FSCustInfo' // 고객정보 (공통)
import FSAmlUtil from '~/src/ui/fs/comm/FSAmlUtil'

import MSPFS313P from '~/src/ui/fs/MSPFS313P'
import MSPFS301P from '~/src/ui/fs/MSPFS301P' // AML_실제소유자여부확인 팝업
import MSPFS305P from '~/src/ui/fs/MSPFS305P' // 고위험승인요청 팝업
import MSPFS315P from '~/src/ui/fs/MSPFS315P' // 자금세탁(AML) 고객확인 재이행 팝업
import MSPFS320P from '~/src/ui/fs/MSPFS320P' // 책임자승인
import MSPFS324P from '~/src/ui/fs/MSPFS324P' // 금감원정보노출사건번호

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPFS314M',
  screenId: 'MSPFS314M',
  components: {
    'fs-header': FSHeader,
    'fs-alert-popup': FSAlertPopup,
    'fs-cust-info': FSCustInfo,

    'msp-fs-313p': MSPFS313P,
    'msp-fs-301p': MSPFS301P,
    'msp-fs-305p': MSPFS305P,
    'msp-fs-315p': MSPFS315P,
    'msp-fs-320p': MSPFS320P,
    'msp-fs-324p': MSPFS324P
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    //공통코드 호출
    this.fn_SetCommCode()
  },
  mounted() {
    this.$bizUtil.insSrnLog(this.$options.screenId)
    //화면 초기화
    this.fn_init()
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      lv_basInfo: this.getStore('fsStore').getters.getBasInfo.data,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
      lv_isClear: false, // 보안키패드 초기화

      lv_masked_val: '', // 마스크 변수 체크

      lv_reObjRtn: {}, // AML실제 소유주여부 팝업 결과
      lv_reCall_yn: '', // 자금세탁(AML) 고객확인 재이행 여부
      lv_end_reCall_yn: 'N', // 자금세탁(AML) 고객확인 재이행 여부 (출금시)

      // 팝업 객체
      pPopup313Obj: {},
      pPopup320Obj: {},

      pPopup301Obj: {},
      pPopup305Obj: {},
      pPopup315Obj: {},
      pPopup324Obj: {},

      redem_gb: {
        value: '',
        disabled: true
      }, //환매구분
      mae_paym_amt: {}, //금액(좌수)
      paym_possamt: {}, //전액출금가능금액
      paym_possqty: {}, //전액출근가능좌수
      mae_pension_not_paym_tot_amt: '', //연금환매 미이체금액 전
      mae_pension_not_paym_pay_amt: '', // 연금환매 미이체금액 후
      mae_part_paym_possamt: {}, //출금금액
      mae_btrans_amt: {}, // 송금금액

      //유선여부
      tel_use_yn: 'N',

      //대리인여부
      agnt_gb: {
        value: 'N',
        disabled: false
      },

      bank_cd: {}, //약정계좌번호1
      bank_acct_no: {}, //약정계좌번호2
      org_tel_no: '', //약정계좌번호3

      // Accordion Toggle
      isActive: false,
      // 공통코드
      lv_commCode: {},
      eaiCommObj: FSCommUtil.gfn_eaiCommObj(),
      // 공통 객체
      pHeaderObj: {
        title: '은행송금'
      },
      //alert
      pAlertPopupObj: {},
      // 고객정보
      pCustInfoObj: {},
      //주민등록번호
      ds_search: {
        rlnm_cnf_no_encr: '',
        passbook_no: '',
        acct_seq_no: '',
        cust_no: ''
      },
      // 계좌정보
      lv_cust_info: {}, // 고객정보
      lv_cust_fss: {}, // 고객정보_금감원정보노출사건번호
      lv_acct_list: [], // 계좌조회목록

      // 검색조건
      lv_ed_accn_no: { value: '' }, // 계좌번호
      lv_ed_acct_gb: {}, // 계좌종류
      lv_ed_cust_nm: {}, // 고객명

      //비밀번호
      passwd: {
        value: '',
        disabled: ''
      },

      //as-is 전역변수
      aml_yn: 'Y', //AML체크
      select_yn: 'N', //조회체크
      gArs_pswn_yn: '', //ARS 비밀번호 체크 여부
      clasId: '',

      //대리인 조회 param
      ds_agent: {
        bfcer_cust_no: '',
        tr_code: ''
      },
      //약정이체계좌번호
      cbo_bank_acct_no: [],

      ds_sy200000_o_01vo: [],
      ds_rp251000_o_01vo: [],

      btn_confirm: true,

      lv_aml_check_data: {}, // 자금세탁방지 결과

      lv_acct_no: '', // 인출가능금액 조회 계좌

      ds_report: {}, // 리포트호출 시 필요한 데이터

      gv_adm_seq_no: 0, // 승인일련번호
      gv_adm_eno: '' // 승인사원번호
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    rdo_ars_gb() {
      // 유선구분
      let rtn = []
      rtn.push({ value: 'N', text: '일반' })
      rtn.push({ value: 'Y', text: '유선' })
      return rtn
    },
    rdo_agnt_chk_yn() {
      // 대리인여부
      let rtn = []
      rtn.push({ value: 'Y', text: '예' })
      rtn.push({ value: 'N', text: '아니오' })
      return rtn
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // 대리인여부 선택 시
    agnt_gb: {
      handler(after, before) {
        if (after.value == 'Y') {
          //           this.fn_agntSelect();
        }
      },
      deep: true
    }
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
     * Function명 : fn_SetMaskedTxt
     * 설명       : 보안키패드 체크
     ******************************************************************************/
    fn_SetMaskedTxt(val) {
      this.lv_masked_val = val
      if (FSCommUtil.gfn_length(this.lv_masked_val) >= 4) {
        this.fn_pwdInputCallBack()
      }
    },

    /******************************************************************************
     * Function명 : fn_Init
     * 설명       : 화면 초기화
     ******************************************************************************/
    fn_init() {
      console.log('dddddddddddddd', this.lv_basInfo)
      // 검색조건
      this.lv_ed_accn_no = FSCommUtil.gfn_dataSet(1, '', false, '', 'ed_accn_no') // 계좌번호
      this.lv_ed_acct_gb = FSCommUtil.gfn_dataSet(1, '', true, '', 'ed_acct_gb') // 계좌종류
      this.lv_ed_cust_nm = FSCommUtil.gfn_dataSet(1)

      this.lv_cust_info = {} // 고객정보
      this.lv_acct_list = []

      //고객정보
      // this.pCustInfoObj = {};

      this.tel_use_yn = 'N' //유선여부
      this.agnt_gb.value = 'N' //대리인여부

      this.passwd = {
        value: '',
        disabled: true
      }

      //대리인여부
      ;(this.agnt_gb = {
        value: 'N',
        disabled: false
      }),
        (this.lv_cust_fss = {})

      this.fn_initAcc()

      this.lv_reObjRtn = {} // AML실제 소유주여부 팝업 결과
      this.lv_reCall_yn = '' // 자금세탁(AML) 고객확인 재이행 여부
      this.lv_end_reCall_yn = 'N' // 자금세탁(AML) 고객확인 재이행 여부 (출금시)

      //책임자 승인
      this.gv_adm_seq_no = 0
      this.gv_adm_eno = ''

      //보안키패드
      this.lv_isClear = !this.lv_isClear ? true : false
      this.lv_masked_val = ''

      // 고객정보 초기화
      this.$refs.fsCustInfo.fn_Init()

      setTimeout(() => {}, 2000)
    },

    /***************************************************************************************
     *	Function명 : fn_initAcc
     *****************************************************************************************/
    fn_initAcc() {
      this.redem_gb = {
        value: '',
        disabled: true
      } //환매구분

      this.mae_paym_amt = {
        value: '',
        disabled: true
      } //금액(좌수)

      this.paym_possamt = {
        value: '',
        disabled: true
      } //전액 출금가능금액

      this.paym_possqty = {
        value: '',
        disabled: true
      } //전액 출근가능좌수

      this.mae_pension_not_paym_tot_amt = '' //연금환매 미이체금액 전
      this.mae_pension_not_paym_pay_amt = '' // 연금환매 미이체금액 후

      this.mae_part_paym_possamt = {
        value: '',
        disabled: true
      } //출금금액

      this.mae_btrans_amt = {
        value: '',
        disabled: true
      } // 송금금액

      this.bank_cd = {
        value: '',
        disabled: true
      } //약정계좌번호1

      this.bank_acct_no = {
        value: '',
        disabled: true
      } //약정계좌번호2

      this.org_tel_no = '' // 전화번호3
    },

    /***************************************************************************************
     *	Function명 : fn_divSearchAccnNoList_CallBack
     *	설명   		 : 계좌번호 목록 공통DIV에서 계좌번호가 조회 된 후 CallBack 메소드이며, 이를 이용하여 소관을 체크한다.
     *****************************************************************************************/
    async fn_divSearchAccnNoList_CallBack() {
      console.log('[fn_divSearchAccnNoList_CallBack Method] START!!!')

      if (this.lv_acct_list != undefined) {
        console.log('[fn_divSearchAccnNoList_CallBack Method] DATA_OBJ : ' + JSON.stringify(this.lv_acct_list))

        var bfcer_accn_no = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue // 계좌번호
        //this.gfn_nvl(this.div_search.div_accn_no.getAccnInfo(0, "bfcer_accn_no"), "");
        var bfcer_cust_no = String(bfcer_accn_no).substr(0, 7)

        // 자금세탁방지 및 테러 자금방저정책 ( AML ) 체크
        this.aml_yn = 'Y'

        let aml_check_data = await FSAmlUtil.gfn_aml_check(this, bfcer_accn_no, 'RP2010', '', this.lv_reCall_yn, this.lv_reObjRtn, this.fn_AlertPopup, this.fn_OpenPopup)
        console.log('aml_check_data AML체크 값 ', aml_check_data)

        if (!aml_check_data) {
          this.aml_yn = 'N'
          this.btn_confirm = true
          return
        }

        console.log('AML_YN ', this.aml_yn)
        this.lv_ed_acct_gb.value = 0
        this.fn_Acct_no_call()
      }
    },

    /******************************************************************************
     * Function명 : fn_pwdInputCallBack
     * 설명       : 비밀번호 입력 콜백
     ******************************************************************************/
    fn_pwdInputCallBack() {
      console.log('비밀번호 입력 콜백')
      if (!this.isMobile) {
        if (FSCommUtil.gfn_length(this.passwd.value) < 4) {
          this.fn_initAcc()
          return
        }
      } else {
        if (FSCommUtil.gfn_length(this.lv_masked_val) < 4) {
          this.fn_initAcc()
          return
        }
      }

      console.log('비밀번호입력시 자동조회')
      this.fn_search()
    },

    /******************************************************************************
     * Function명 : fn_search
     * 설명       : 조회
     ******************************************************************************/
    fn_search() {
      console.log('조회')
      if (this.aml_yn == 'N') {
        let t_popupObj = { content: 'AML 확인해주세요.' }
        this.fn_AlertPopup(0, t_popupObj)
        return
      }
      //비밀번호
      var sPassWd = this.isMobile ? this.lv_masked_val : this.passwd.value

      if (this.tel_use_yn != 'Y') {
        if (FSCommUtil.gfn_isNull(sPassWd) && FSCommUtil.gfn_length(sPassWd) < 4) {
          FSCommUtil.gfn_validate(this, '비밀번호 입력오류입니다.')
          // this.div_search.edt_passwd.setFocus();
          this.$refs['passwd'].focus()
          return
        }
      }

      //조회체크
      this.select_yn = 'Y'
      this.fn_getAllRedemPossamt() // 전액 인출가능금액 조회
    },

    /******************************************************************************
     * Function명 : fn_getAllRedemPossamt
     * 설명       : 전액 인출가능금액 조회
     ******************************************************************************/
    fn_getAllRedemPossamt() {
      console.log('fn_getAllRedemPossamt 전액인출가능금액 조회 ')

      // 초기화 필요
      this.fn_initAcc()
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570155_S'
      this.eaiCommObj.params = {
        bfcer_accn_no: this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue, //this.lv_ed_accn_no.value, // 수익증권계좌번호, Length : 13
        bfcer_rpus_apl_amt: this.mae_paym_amt.value, // 수익증권환매신청금액, Length : 25
        bfcer_rpus_sc_cd: '11', // 수익증권환매구분코드, Length : 2
        callcenter_link_yn: 'N', // 연결여부, Length : 1
        comp_cd: '00001', // 회사코드, Length : 10                          // 실명확인번호구분코드
        secure_passv: this.passwd.value, // 비밀번호
        ars_pswn_yn: '', // ARS암호화
        tr_code: 'RP2010', // 처리업무코드
        proc_gb: 'S' // 업무구분
      }
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_getAllRedemPossamtCallBack)
    },

    /******************************************************************************
     * Function명 : fn_getAllRedemPossamtCallBack
     * 설명       : 조회 콜백
     ******************************************************************************/
    fn_getAllRedemPossamtCallBack(response) {
      this.ds_rp251000_o_01vo = response.data.rp251000_O_02VO

      if (response.data.error_msg == '809900' || this.ds_rp251000_o_01vo.length > 0 || response.data.error_msg == ' ') {
        console.log('인출가능금액조회 response', response)

        var eval_amt = response.data.eval_amt
        this.lv_acct_no = response.data.acct_no
        if (Number(eval_amt) > 0) {
          this.btn_confirm = false //확인버튼 비활성화 여부
        } else {
          let t_popupObj = { content: '출금가능금액이 없습니다 확인하십시오.' }
          this.fn_AlertPopup(0, t_popupObj)
          return
        }
        console.log('this.redem_gb.value111111111', this.redem_gb.value)
        // 환매구분 활성화
        this.redem_gb.disabled = false
        if (this.ds_rp251000_i_01vo) {
          this.redem_gb.value = this.ds_rp251000_i_01vo[0].bfcer_rpus_sc_cd
          console.log('this.redem_gb', this.redem_gb.value)
        }

        // 금액(좌수) 활성화
        this.mae_paym_amt.disabled = false
        this.mae_btrans_amt.disabled = false
        this.bank_acct_no.disabled = false
        this.fn_cbo_btrans_acct_no_onchanged(0)

        var data = response.data

        console.log('this.redem_gb.value ', this.redem_gb.value)
        if (this.redem_gb.value == '11' || this.redem_gb.value == 11) {
          //전액환매
          //금액및 출금신청금액에 출금가능금액 셋팅
          this.mae_part_paym_possamt.value = data.paym_possamt
          console.log('전액 : ', data.paym_possamt)
          //전액출금가능금액
          this.paym_possamt.value = data.paym_possamt
          //전액출금가능좌수
          this.paym_possqty.value = data.paym_possqty
          console.log('전액출금가능좌수', this.paym_possqty.value)

          //연금환매 이전금액
          this.mae_pension_not_paym_tot_amt = data.pension_not_paym_tot_amt
          this.mae_pension_not_paym_pay_amt = data.pension_not_paym_pay_amt

          //전액인경우 입력못하게막음
          this.mae_paym_amt.disabled = true

          //은행이체
          if (Number(this.mae_part_paym_possamt.value) > 0) {
            this.mae_btrans_amt.value = this.mae_part_paym_possamt.value
          } else {
            //인출가능금액이 없으므로 송금금액 막는다..
            this.mae_btrans_amt.disabled = true
            this.mae_btrans_amt.value = this.mae_part_paym_possamt.value
          }
        } else if (this.redem_gb.value == '12' || this.redem_gb.value == 12) {
          //좌수환매
          //출금신청금액
          this.mae_part_paym_possamt.value = data.paym_possamt
        } else if (this.redem_gb.value == '13' || this.redem_gb.value == 13) {
          //정액환매
          //출금신청금액
          this.mae_part_paym_possamt.value = data.paym_possamt
          //송금금액= 금액(좌수)
          this.mae_btrans_amt.value = data.paym_possamt
          this.mae_paym_amt.disabled = false
        } else {
          //전액출금가능금액
          this.paym_possamt.value = data.paym_possamt
        }
        //조회시만
        if (this.select_yn == 'Y') {
          console.log('빈값선택하기')
          //연금환매 이전금액
          this.mae_pension_not_paym_tot_amt = data.pension_not_paym_tot_amt
          this.mae_pension_not_paym_pay_amt = data.pension_not_paym_pay_amt
          //전액출금가능좌수
          this.paym_possqty.value = data.paym_possqty
          //출금신청금액
          this.mae_part_paym_possamt.value = data.paym_possamt
        }

        //약정계좌번호 인덱스 0으로 설정
        this.bank_acct_no.value = 0
      } else {
        let t_valList = []
        let t_popupObj = {}
        t_popupObj.content = FSMessageUtil.commMessage(t_valList, response.data.error_msg)[response.data.error_msg.split('||')[0]]
        this.fn_AlertPopup(0, t_popupObj)

        this.btn_confirm = true
      }
    },

    /**************************************************************************************
     * Function명 : fn_searchBTransAcctNo
     * 설명       : 약정계좌조회
     ***************************************************************************************/
    fn_searchBTransAcctNo() {
      console.log('약정계좌 조회')
      var sCustNo = FSCommUtil.gfn_substr(this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue, 0, 7)
      console.log('custNo =====', sCustNo)
      this.cbo_bank_acct_no = []

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570202_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: sCustNo,
        tr_code: 'SY2000', //화면ID
        secure_passv: this.passwd.value, //핀패드비밀번호
        sup_id: '', //승인자사번
        aprv_sno: '', //승인업무식별번호
        ars_pswn_yn: '' //ARS비밀번호여부
      }

      // //암호화key 서버 올림
      // var encALGO = "SEED";
      // sArgument += " ENCSYMM = " + nexacro.wrapQuote(nexacro.secureSession.getKeyIV());
      // sArgument += " ENCALGO = " + nexacro.wrapQuote(encALGO);

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_searchBTransAcctNoCallBack)
    },

    /**************************************************************************************
     * Function명 : fn_searchBTransAcctNoCallBack
     * 설명       : 약정계좌조회 콜백
     ***************************************************************************************/
    fn_searchBTransAcctNoCallBack(response) {
      console.log('약정계좌 조회 콜백 ', response)
      this.ds_sy200000_o_01vo = response.data.sy200000_O_00VO
      this.ds_sy200000_o_01vo.forEach((item, idx) => {
        //약정계좌목록 재구성
        this.cbo_bank_acct_no.push({
          value: idx,
          text: item.btrans_acct_no.substr(0, 3) + '*****' + item.btrans_acct_no.substr(8), // item.btrans_acct_no,
          bank_acct_no: item.bank_acct_no,
          account_id: item.account_id,
          seq_no: item.seq_no,
          trd_bank_cd: item.trd_bank_cd
        })
      })

      if (FSCommUtil.gfn_length(this.ds_sy200000_o_01vo) == 0) {
        let t_popupObj = { content: '등록된 약정계좌가 없습니다.' }
        this.fn_AlertPopup(0, t_popupObj)

        //약정계좌번호 disabled
        this.bank_cd.disabled = true
        this.bank_acct_no.disabled = true
      } else {
        this.fn_cbo_btrans_acct_no_onchanged(0)
      }
    },

    /**************************************************************************************
     * Function명 : fn_btransLimitAmtCheck
     * 설명       : 약정계좌번호 변경시
     ***************************************************************************************/
    fn_cbo_btrans_acct_no_onchanged(postindex) {
      if (this.bank_acct_no.disabled) {
        return
      }

      if (FSCommUtil.gfn_isEmpty(postindex)) {
        postindex = this.bank_acct_no.value
      }
      console.log('약정계좌 콤보에 넣기 index -->', postindex)

      var trd_bank_cd = '' //은행코드
      var bank_acct_no = '' //계좌번호
      var bank_acct_nm = '' //예금주

      if (postindex >= 0) {
        trd_bank_cd = this.ds_sy200000_o_01vo[postindex].trd_bank_cd
        bank_acct_no = this.ds_sy200000_o_01vo[postindex].bank_acct_no
        bank_acct_nm = this.ds_sy200000_o_01vo[postindex].bank_acct_nm
      }

      //약정계좌번호 설정
      this.bank_cd.value = trd_bank_cd
    },

    /**************************************************************************************
     * Function명 : fn_mae_paym_amt_onchanged
     * 설명       : 금액(좌수)입력시
     ***************************************************************************************/
    fn_mae_paym_amt_onchanged(e) {
      // 1) 지급금액 최대 12자리 입력 제한
      // 2) 1억원 이상 입력 시 팝업 발생
      if (this.mae_paym_amt.value > 999999999999) {
        let t_popupObj = { content: '지급금액은 최대 12자리까지만 입력 가능합니다.' }
        this.fn_AlertPopup(0, t_popupObj)
        return false
      }

      if (this.mae_paym_amt.value > 100000000) {
        let t_popupObj = { content: '입력하신 금액이 1억원을 초과하였습니다. 금액을 확인해주시기 바랍니다.' }
        this.fn_AlertPopup(0, t_popupObj)
      }

      // 입력구분에 따른 인출가능금액조회
      this.fn_inputRedemPossamt()
    },
    /**************************************************************************************
     * Function명 : fn_btransLimitAmtCheck
     * 설명       : 이체한도관리 조회
     ***************************************************************************************/
    //이체한도관리 조회-2012.08.24 김진규
    fn_btransLimitAmtCheck() {
      console.log('이체한도관리 조회')
      var sCustNo = String(this.lv_ed_accn_no.value).substr(0, 7)

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570225_S'
      this.eaiCommObj.params = {
        bfcer_cust_no: sCustNo,
        bnk_trsf_lmt_amt_gb: '',
        secure_passv: this.passwd.value,
        ars_pswn_yn: '',
        proc_gb: 'S'
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_btransLimitAmtCheckCallBack)
    },

    /**************************************************************************************
     * Function명 : fn_btransLimitAmtCheckCallBack
     * 설명       : 이체한도관리 조회 콜백
     ***************************************************************************************/
    fn_btransLimitAmtCheckCallBack(response) {
      console.log('이체한도관리 조회 콜백 ', response)
      let t_popupObj = {}
      var nBtransAmt = this.mae_btrans_amt.value

      if (FSCommUtil.gfn_isNull(nBtransAmt)) nBtransAmt = ''
      nBtransAmt = Number(nBtransAmt)

      console.log('이체한도관리 조회완료')
      //#1210 은행이체1회출금한도 지정 에서 한도지정여부 확인
      console.log('이체한도관리 조회완료건수 : ' + FSCommUtil.gfn_length(response.data.cu121000_O_00VO))
      var data = response.data.cu121000_O_00VO
      if (FSCommUtil.gfn_length(data) > 0) {
        var nBnkTrsfLmtAmt = data[0].bnk_trsf_lmt_amt
        var sBnkTrsfLmtAmtNm = data[0].bnk_trsk_lmt_amt_nm

        console.log('nBnkTrsfLmtAmt :' + nBnkTrsfLmtAmt)
        console.log('sBnkTrsfLmtAmtNm :' + sBnkTrsfLmtAmtNm)

        if (FSCommUtil.gfn_isNull(nBnkTrsfLmtAmt)) nBnkTrsfLmtAmt = ''
        nBnkTrsfLmtAmt = Number(nBnkTrsfLmtAmt)

        if (FSCommUtil.gfn_isNull(sBnkTrsfLmtAmtNm)) sBnkTrsfLmtAmtNm = ''

        if (nBtransAmt > nBnkTrsfLmtAmt) {
          var sMsg = '고객님의 1회 이체한도는 ' + sBnkTrsfLmtAmtNm + ' 원입니다. (1일한도 無)'
          sMsg += '\n1회이체한도 지정은 #1210 이체1회한도관리 화면에서 변경가능하십니다.'

          t_popupObj.content = sMsg
          this.fn_AlertPopup(0, t_popupObj)

          return
        }

        if (nBtransAmt >= 1000000000) {
          // var objRtn = this.gfn_getMessage("Confirm", "1회이체 10억원 이상 시 본사에 모계좌잔액조회 요청하셨습니까?");
          // if (objRtn == false || objRtn == "Cancel") return false;
          let t_popupObj = {
            cancel: false,
            confirm: true,
            confirmFunc: '',
            content: '1회이체 10억원 이상 시 본사에 모계좌잔액조회 요청하셨습니까?'
          }
          this.fn_AlertPopup(0, t_popupObj)
        }
      } else {
        // ASR180600266_은행이체 금액별 결재프로세스 추가 요청 2018.07.03
        // #1210 등록된 1회 이체 한도 금액이 없을 때 기본 1억 -> 5억 체크하도록 변경
        if (nBtransAmt > 500000000) {
          t_popupObj.content = '1회 이체한도는 5억원입니다. (1일한도 無)'
          this.fn_AlertPopup(0, t_popupObj)
          return
        }
      }

      //예탁금이용료 발생처리는  제외.
      this.fn_trdComfirm()

      console.log('출금호출')
      //출금호출
      this.fn_cashPayment()
    },

    /**************************************************************************************
     * Function명 : fn_trdComfirm
     * 설명       : 매매거래 안내 멘트
     ***************************************************************************************/
    fn_trdComfirm() {
      console.log('매매거래 안내 멘트', this.lv_acct_list)
      var vSrcTr_cd = 'RP2010'
      var vCust_nm = '' //this.ds_acct_no.getColumn(0,'fnd_cust_nm'); // SY0010
      var vBank_nm = ''
      var vTrd_amt = 0
      var vFund_cd = this.lv_ed_acct_gb.value

      console.log('매매거래 안내멘트')

      var sRedemGb = this.redem_gb.value

      // var vTrd_bank_cd  = "";//this.ds_sy200000_o_01vo.getColumn(nRow, "trd_bank_cd") ;
      var vBank_acct_no = this.bank_acct_no.value

      var vBank_cd_nm = this.bank_cd.value // 수정필요this.ds_cbo_btrans_bank_cd.getColumn( this.ds_cbo_btrans_bank_cd.findRow( "cdDtl", vTrd_bank_cd ),"cNm");

      vBank_nm = vBank_cd_nm + ' ' + vBank_acct_no

      var nPaymPossAmt = this.mae_part_paym_possamt.value
      var nPaymPossQty = this.paym_possqty.value
      console.log('전액출금가능좌수222', this.paym_possqty.value)
      var nPaymAmt = this.mae_btrans_amt.value

      if (FSCommUtil.gfn_isNull(nPaymPossAmt)) nPaymPossAmt = ''
      nPaymPossAmt = Number(nPaymPossAmt)
      if (FSCommUtil.gfn_isNull(nPaymPossQty)) nPaymPossQty = ''
      nPaymPossQty = Number(nPaymPossQty)
      if (FSCommUtil.gfn_isNull(nPaymAmt)) nPaymAmt = ''
      nPaymAmt = Number(nPaymAmt)

      //전액환매일경우 - 금액세팅
      if (sRedemGb == '11') {
        //전액환매
        if (this.lv_ed_accn_no.value == '100000') {
          //예수금
          vTrd_amt = nPaymPossAmt
        } else {
          //  mmf 환매일 경우
          vTrd_amt = nPaymPossQty
        }
      } else {
        vTrd_amt = nPaymAmt
      }

      var oArgs = {}
      oArgs.fnd_cust_nm = vCust_nm // 펀드고객명
      oArgs.bfcer_rpus_apl_sc_cd = sRedemGb // 수익증권환매신청구분코드
      oArgs.bfcer_fnd_cd = vFund_cd // 수익증권펀드코드
      oArgs.trd_amt = vTrd_amt // 금액
      oArgs.bank_nm = vBank_nm // 은행명
      oArgs.pgmId = vSrcTr_cd
    },

    /*******************************************************************************
       - 현금 출금
      ********************************************************************************/
    fn_cashPayment() {
      console.log('현금 출금', this.cbo_btrans_acct_no)
      console.log('현금 출금', this.ds_sy200000_o_01vo)
      var idx = this.bank_acct_no.value //this.cbo_btrans_acct_no.index; //확인중 todo
      var seq_no = this.ds_sy200000_o_01vo[idx].seq_no //약정일련번호
      var sContrAcctNo = this.ds_sy200000_o_01vo[idx].bank_acct_no //약정계좌번호
      var sBankCd = this.ds_sy200000_o_01vo[idx].trd_bank_cd //은행코드
      var sAcctId = this.ds_sy200000_o_01vo[idx].account_id //
      var sAcctBpYn = this.ds_sy200000_o_01vo[idx].acct_bp_yn //
      var sBankAcctNm = this.ds_sy200000_o_01vo[idx].bank_acct_nm

      var sMedia = 'I'
      var sTel_use_yn = 'N' // 유선구분 테블릿에서는 일반으로 고정
      var sAcctNo = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue
      var sRedemGb = this.redem_gb.value
      var sPaymAmt = this.mae_paym_amt.value
      var sCashPaymAmt = this.mae_btrans_amt.value //송금금액=현금금액

      this.btn_confirm = true

      this.eaiCommObj.params = {}

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570224_S'
      this.eaiCommObj.params = {
        // tr_code             : "RP2010", //거래코드
        account_id: sAcctId, // 계좌 ID, Length : 40
        acct_bp_yn: sAcctBpYn, // 계좌 bp 여부, Length : 1
        acct_seq_no: String(sAcctNo).substr(10, 3), // 계좌일련번호, Length : 40
        bank_cd: sBankCd, // 은행코드, Length : 10
        bfcer_cstgr_no: String(sAcctNo).substr(0, 10), // 고객그룹번호, Length : 10
        btrans_acct_nm: sBankAcctNm, // 입금계좌명, Length : 150
        btrans_acct_no: '', // 입금계좌, Length : 44
        btrans_req_area: '', // 의뢰인연락처1, Length : 40
        btrans_req_nm: '', // 의뢰인, Length : 80
        btrans_req_rname_no: '', // 의뢰인주민번호, Length : 40
        btrans_req_seq: '', // 의뢰인연락처3, Length : 20
        btrans_req_station: '', // 의뢰인연락처2, Length : 40
        cash_paym_amt: sCashPaymAmt, // 현금지급액, Length : 25
        contr_acct_no: sContrAcctNo, // 약정계좌, Length : 35
        cust_id: '', // 고객id, Length : 10
        cust_nm: sBankAcctNm, // 예금주명, Length : 150
        cust_no: String(sAcctNo).substr(0, 7), // 고객번호, Length : 7
        org_tel_no: '', // 원전문번호, Length : 40
        paym_amt: sPaymAmt, // 출금액, Length : 21
        paym_cal_issue_yn: '1', // 지급계산서발급여부, Length : 1
        paym_possamt: this.paym_possamt.value, // 출금가능금액, Length : 20
        paym_repay_mth: '26', // 출금지급방법, Length : 40
        proc_gb: 'I', // 처리구분코드, Length : 1
        redem_gb: sRedemGb, // 환매구분, Length : 1
        resv_paym_amt: '', // 본사지준지급액, Length : 25
        seq_no: seq_no, // 계좌일련번호, Length : 18
        win_alt_paym_amt: '', // 창구대체지급액, Length : 25
        secure_passv: this.passwd.value, //
        depo_use_fee_paym_yn: '1', // 예탁금이용료지급계산서 발급여부, Length : 1
        sup_id: this.gv_adm_eno, // 승인자 정보
        aprv_sno: this.gv_adm_seq_no, // 책임자 승인 요청 번호
        tel_use_yn: '',
        media: 'I'
      }
      this.eaiCommObj.params.proc_gb = 'I'

      console.log('this.eaiCommObj.params???????', this.eaiCommObj.params)
      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_cashPaymentCallBack)
      console.log('this.eaiCommObj.params???????', this.eaiCommObj.params.proc_gb)
      //서버에 호출 할 정보를 셋팅해준다.
      // var sSvcID        = "insertRP201000_Service";
      // var sURL          = "svc::insertRP201000_Service.do";
      // var sInDatasets   = "rp201000_i_00vo=ds_rp201000_i_00vo";
      // var sOutDatasets  = "ds_rp201000_o_00vo=rp201000_o_00vo gds_admission_info=admission_info";
      // var sArgument     = "";
      // var sCallbackFunc = "fn_callBack";

      // //암호화key 서버 올림
      // var encALGO = "SEED";
      // sArgument += " ENCSYMM = " + nexacro.wrapQuote(nexacro.secureSession.getKeyIV());
      // sArgument += " ENCALGO = " + nexacro.wrapQuote(encALGO);

      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
    },

    /******************************************************************************
     * Function명 : fn_cashPaymentCallBack
     * 설명       : 출금 콜백
     ******************************************************************************/
    fn_cashPaymentCallBack(res) {
      console.log('this.eaiCommObj.params???????', this.eaiCommObj.params.proc_gb)
      console.log('\t C391_F10570224_S I Callback', res)
      if (res.data.error_msg == '050013' && this.gv_adm_seq_no == 0 && this.gv_adm_eno == '') {
        let paramData = {
          adm_proc_gb: res.data.adm_proc_gb, // 승인처리구분 : 0 - 승인요청 1 - 승인응답
          trt_ymd: res.data.trt_ymd, // 처리일
          bfcer_fof_cd: res.data.bfcer_fof_cd, // 처리지점
          bfcer_fof_nm: res.data.bfcer_fof_nm, // 처리지점명
          aprv_req_sno: res.data.aprv_req_sno, // 승인일련번호
          req_tr_code: res.data.req_tr_code, // 처리요청 tr_code
          req_tr_code_nm: res.data.req_tr_code_nm, // 처리화면명
          rqstr_eno: res.data.rqstr_eno, // 요청사원번호
          rqstr_eno_nm: res.data.rqstr_eno_nm, // 요청사원명
          message: res.data.message, // 메시지
          fileSeq: res.data.fileSeq, // 캡쳐 파일일련번호
          orgFileNm: res.data.orgFileNm, // 캡쳐 원본파일명
          fileChk: res.data.fileChk, // 캡쳐 파일체크
          gv_adm_seq_no: res.data.gv_adm_seq_no, // 승인일련번호
          gv_adm_eno: res.data.gv_adm_eno, // 승인사원번호
          adm_eno: res.data.adm_eno // 승인자 리스트
        }

        console.log('책임자 승인 paramData : ', paramData)

        this.pPopup320Obj = paramData
        this.$refs.popup320.fn_Open()
        return
      }

      if (FSCommUtil.gfn_trim(res.data.error_msg) != '' && res.data.error_msg != '809905') {
        // this.fn_AlertPopup(0, {content:'책임자 승인 팝업화면이 구현되어 있지 않습니다.'})
        // return

        console.log('\t C391_F10570224_S 처리구분(I) 오류 ...')
        this.fn_AlertPopup(0, { content: FSMessageUtil.commMessage([], res.data.error_msg)[res.data.error_msg.substring(0, 6)] })
        return
      }

      if (res.data.aprv_req_sno != 0 && this.gv_adm_seq_no == 0 && this.gv_adm_eno == '') {
        let o = res.data

        let adm_eno_list = []
        let adm_eno = {
          staf_eno: o.staf_eno, // 책임자 사원번호
          staf_nm: o.staf_nm, // 책임자 명
          fof_nm: o.fof_nm, // 책임자 지점명
          auth_grp_cd_nm: o.auth_grp_cd_nm, // 책임자 그룹명
          user_ip: o.user_ip // 책임자 IP
        }
        if (!FSCommUtil.gfn_isNull(o.staf_eno)) {
          adm_eno_list.push(adm_eno)
        }

        let paramData = {
          adm_proc_gb: o.adm_proc_gb, // 승인처리구분 : 0 - 승인요청 1 - 승인응답
          trt_ymd: o.trt_ymd, // 처리일
          bfcer_fof_cd: o.bfcer_fof_cd, // 처리지점
          bfcer_fof_nm: o.bfcer_fof_nm, // 처리지점명
          aprv_req_sno: o.aprv_req_sno, // 승인일련번호
          req_tr_code: o.req_tr_code, // 처리요청 tr_code
          req_tr_code_nm: o.req_tr_code_nm, // 처리화면명
          rqstr_eno: o.rqstr_eno, // 요청사원번호
          rqstr_eno_nm: o.rqstr_eno_nm, // 요청사원명
          message: o.message, // 메시지
          fileSeq: o.fileSeq, // 캡쳐 파일일련번호
          orgFileNm: o.orgFileNm, // 캡쳐 원본파일명
          fileChk: o.fileChk, // 캡쳐 파일체크
          gv_adm_seq_no: o.gv_adm_seq_no, // 승인일련번호
          gv_adm_eno: o.gv_adm_eno, // 승인사원번호
          adm_eno_list: adm_eno_list // 승인자 리스트
        }

        console.log('책임자 승인 paramData : ', paramData)

        this.fn_OpenPopup('MSPFS320P', paramData)
        console.log('팝업열림')

        this.fn_AlertPopup(0, { content: FSMessageUtil.commMessage([], res.data.error_msg)[res.data.error_msg.substring(0, 6)] })
        return
      } else {
        console.log('\t C391_F10570224_S 처리구분(S1)으로 다시 호출')
        var org_tel_no = res.data.org_tel_no
        this.fn_cashPaymentCheck(org_tel_no)
      }
    },

    /******************************************************************************
     * Function명 : fn_cashPaymentCheck
     * 설명       : 출금 처리 후 지급 내역 확인하기 위해 재호출
     ******************************************************************************/
    fn_cashPaymentCheck(org_tel_no) {
      console.log('지급내역 확인 S1 : ')

      this.eaiCommObj.params.proc_gb = 'S1'
      this.eaiCommObj.params.org_tel_no = org_tel_no

      this.eaiCommObj.params.paym_amt = this.mae_btrans_amt.value
      this.eaiCommObj.params.cash_paym_amt = this.mae_btrans_amt.value

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_call_back_save)
    },

    /******************************************************************************
     * Function명 : fn_call_back_save
     * 설명       : 출금 처리 후 지급 내역 확인하기 위해 재호출 후
     ******************************************************************************/
    fn_call_back_save(res) {
      console.log('\t C391_F10570224_S S1 Callback', res)
      console.log('\t res.data.rt_cd : ' + res.data.rt_cd + ']')
      if (res.data.error_msg == '809905') {
        console.log('\t C391_F10570224_S rt_cd ...')

        // if ( res.data.rt_cd = '1' )
        // 	{
        // 	this.fn_AlertPopup(0, {content:'은행이체중 오류가 발생했습니다. 본사에 문의해주시길 바랍니다.'})
        // 	return
        // 	}
        // else if ( res.data.rt_cd = '4' )
        // 	{
        // 	return
        // 	}
        // else if ( res.data.rt_cd = '3' )
        // 	{
        // 	this.fn_AlertPopup(0, {content:'은행송금목록 팝업이 구현되어 있지 않습니다.'})
        // 	return
        // 	}
        // else
        // 	{
        // 전자문서
        // this.fn_ReportPrint(res.data);
        var cust_no = this.lv_ed_accn_no.value.substr(0, 7)
        this.ds_report = res.data
        let t_msg = FSMessageUtil.commMessage([])[res.data.error_msg]
        let lv_vm = this
        let t_popupObj = {
          confirm: true,
          confirmFunc: lv_vm.fn_ReportPrint,
          contentTitle: '은행송금 완료',
          confirmData: {
            bfcer_cust_no: cust_no
          },
          content: [t_msg]
        }
        this.fn_AlertPopup(9, t_popupObj)

        this.fn_init()
        // 	}
      } else {
        let t_valList = []
        let t_popupObj = {}
        t_popupObj.content = FSMessageUtil.commMessage(t_valList)[res.data.error_msg]
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_ReportPrint
     * 설명       : 전자서식 호출
     ******************************************************************************/
    async fn_ReportPrint() {
      console.log('fn_ReportPrint....')

      let formList = []
      let FS000027 = {
        formId: 'FS000027',
        formdata: this.redem_gb.value == '11' ? '전액인출' : ''
      } // 출금신청서(+전자서명)

      let o = {
        bfcer_cust_no: this.ds_report.bfcer_cust_no,
        frm_issnc_ymd: this.ds_report.frm_issnc_ymd,
        issnc_hstr_sno: this.ds_report.issnc_hstr_sno,
        bfcer_busn_trt_id: 'RP2010',
        rprt_frm_no: '0113'
      }

      console.log('전자서식 Parameter : ' + JSON.stringify(o))

      FS000027.params = {
        reportParam: JSON.stringify(o),
        reportUrl: 'reportCO070500_Info'
      }

      formList.push(FS000027)
      FSInfoUtil.commReportInfo(this, formList)
    },

    /******************************************************************************
     * Function명 : fn_AgentCheck
     * 설명       : 대리인 만기체크
     ******************************************************************************/
    fn_AgentCheck() {
      console.log('fn_AgentCheck...')
      if (this.agnt_gb.value == 'N') return

      // 초기화
      this.lv_ed_accn_no.error = false

      if (FSCommUtil.gfn_isNull(this.lv_ed_accn_no.value)) {
        FSCommUtil.gfn_validate(this, '계좌번호가 입력되지 않았습니다.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        this.agnt_gb.value = 'N'
        return
      } else if (FSCommUtil.gfn_length(this.lv_ed_accn_no.value) != 10) {
        FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
        this.lv_ed_accn_no.error = true
        this.$refs['ed_accn_no'].focus()
        this.agnt_gb.value = 'N'
        return
      }

      let t_params = {
        // PO 호출 조회조건
        bfcer_cust_no: this.lv_ed_accn_no.value.substr(0, 7),
        tr_code: 'RP201000',

        // 추가정보
        menu_id: 'MSPFS314M'
        //btn_id        : 'agnt_gb',
      }
      FSInfoUtil.agentCheck(this, t_params, this.fn_AlertPopup)
    },

    /***************************************************************************************
     * Function명 : fn_Rad_gubun_chg
     * 설명       : 환매구분변경시 Onchage Event - 조회구분 변경시 입력값 변경
     *****************************************************************************************/
    fn_Rad_gubun_chg() {
      var aValue = this.redem_gb.value
      console.log('환매구분변경', this.redem_gb.value)
      if (aValue == '11' || aValue == '14') {
        this.mae_paym_amt.value = ''
        this.mae_paym_amt.disabled = true
      } else {
        this.mae_paym_amt.disabled = false
      }

      // 입력구분에 따른 인출가능금액조회
      if (aValue == '11') {
        //전액환매
        this.mae_paym_amt.disabled = true
        this.mae_paym_amt.value = ''
        this.mae_btrans_amt.value = ''

        //자동호출
        //입력구분에 따른 인출가능금액조회
        this.fn_inputRedemPossamt()
      } else {
        this.mae_paym_amt.value = '' //금액(좌수)
        this.mae_paym_amt.disabled = false
        this.mae_btrans_amt.value = '' //출금금액
      }
    },

    /***************************************************************************************
     * Function명 : fn_inputRedemPossamt
     * 설명       : 입력 좌수에 대한 출금액 (인출가능금액) 조회
     *****************************************************************************************/
    fn_inputRedemPossamt() {
      console.log('fn_inputRedemPossamt1', this.redem_gb.value)
      console.log('fn_inputRedemPossamt2', this.passwd.value)
      if (FSCommUtil.gfn_isNull(this.redem_gb.value)) this.redem_gb.value = ''
      if (FSCommUtil.gfn_isNull(this.passwd.value)) this.passwd.value = ''
      if (FSCommUtil.gfn_isNull(this.lv_masked_val)) this.lv_masked_val = ''
      if (FSCommUtil.gfn_length(this.lv_acct_list) == 0) return

      if (!this.isMobile) {
        if (String(this.passwd.value).length < 4) return
      } else {
        if (String(this.lv_masked_val).length < 4) return
      }

      // 금액(좌수) 활성화
      this.mae_paym_amt.disabled = true
      this.mae_btrans_amt.disabled = true
      this.bank_cd = {
        value: '',
        disabled: true
      }
      this.bank_acct_no.disabled = true

      this.mae_part_paym_possamt.value = ''
      this.mae_btrans_amt.value = ''

      // 환매구분
      if (this.redem_gb.value != '11' && this.redem_gb.value != '12' && this.redem_gb.value != '13' && this.redem_gb.value != '14' && this.redem_gb.value != '15') return

      // 좌수/금액 부분
      if (this.redem_gb.value == '12' || this.redem_gb.value == '13' || this.redem_gb.value == '15') {
        var nPaymAmt = this.mae_paym_amt.value
        if (FSCommUtil.gfn_isNull(this.mae_paym_amt.value)) this.mae_paym_amt.value = 0
        this.mae_paym_amt.value = Number(this.mae_paym_amt.value)
        if (this.mae_paym_amt.value <= 0) return
      }

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10570155_S'
      this.eaiCommObj.params = {
        bfcer_accn_no: this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue,
        bfcer_rpus_apl_amt: this.mae_paym_amt.value, // this.mae_paym_amt.value, // 수익증권환매신청금액, Length : 25
        bfcer_rpus_sc_cd: this.redem_gb.value, //"11", // 수익증권환매구분코드, Length : 2
        callcenter_link_yn: 'N', // 연결여부, Length : 1
        comp_cd: '00001', // 회사코드, Length : 10                          // 실명확인번호구분코드
        secure_passv: this.passwd.value, // 비밀번호
        ars_pswn_yn: '', // ARS암호화
        tr_code: 'RP2010', // 처리업무코드
        proc_gb: 'S0' // 업무구분
      }

      FSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_getAllRedemPossamtCallBack)
    },

    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회
     ******************************************************************************/
    fn_SearchValid(event, flag) {
      console.log('fn_SearchValid.....')

      let t_accn_no = this.lv_ed_accn_no.value

      if (FSCommUtil.gfn_length(t_accn_no) >= 10) {
        this.lv_ed_accn_no.error = false

        if (!FSCommUtil.gfn_isNum(t_accn_no)) {
          FSCommUtil.gfn_validate(this, '계좌번호 형식에 맞지 않습니다.')
          this.lv_ed_accn_no.error = true
          this.$refs['ed_accn_no'].focus()
          return
        } else {
          if (FSCommUtil.gfn_isNum(event.key) || event.key == 'v' || flag == 'P' || this.isMobile) {
            // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

            let t_data = {}
            t_data.bfcer_cust_no = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
            FSInfoUtil.commInfo(this, 4, t_data, this.fn_search_callback_fssInfoExpsrEvntNo)

            // // 계좌목록조회
            // this.fn_AccnNoList(t_accn_no)
          }
        }
      } else if (t_accn_no.length > 10 || event.key == 'Delete' || event.key == 'Backspace') {
        // this.lv_ed_accn_no.value = FSCommUtil.gfn_mask(t_accn_no)

        // 고객정보 펼치기 정보 초기화
        this.$refs.fsCustInfo.fn_Init()

        // 계좌목록/고객명 초기화
        this.lv_acct_list = []
        this.lv_ed_acct_gb.disabled = true
        this.lv_ed_cust_nm.value = ''
        // 2025.09.24 ASR240701139 / AML 및 고객확인 체크보완
        this.lv_reObjRtn = {} // AML실제 소유주여부 팝업 결과
        this.lv_reCall_yn = '' // 자금세탁(AML) 고객확인 재이행 여부
        this.lv_end_reCall_yn = 'N' // 자금세탁(AML) 고객확인 재이행 여부 (출금시)

        this.lv_cust_fss = {}

        this.passwd = {
          value: '',
          disabled: true
        }

        this.fn_initAcc()
      }
    },

    /******************************************************************************
     * Function명 : fn_AccnNoList, fn_AccnNoListResult
     * 설명       : 금감원정보노출사건번호 체크
     ******************************************************************************/
    fn_search_callback_fssInfoExpsrEvntNo(pResultData) {
      console.log(pResultData)
      if (!FSCommUtil.gfn_isNull(pResultData.data.eaf1MDI0083_O_OOVO)) {
        this.lv_cust_fss = pResultData.data.eaf1MDI0083_O_OOVO[0]
        console.log(this.lv_cust_fss)
      }
      // 계좌목록조회
      this.fn_AccnNoList(this.lv_ed_accn_no.value)
    },

    /******************************************************************************
     * Function명 : fn_AccnNoList, fn_AccnNoListResult
     * 설명       : 계좌목록조회
     ******************************************************************************/
    fn_AccnNoList(pAccnNo) {
      console.log('fn_AccnNoList.....')

      let t_data = { bfcer_cstgr_no: pAccnNo, input_check: '8', int_tr_yn: 'N', bridge_aply_cd: 'Y', bfcer_accn_stat_cd: 'Y' }
      FSInfoUtil.commInfo(this, 2, t_data, this.fn_AccnNoListResult)
    },

    async fn_AccnNoListResult(pResultData) {
      console.log('fn_AccnNoListResult.....')

      let t_data = pResultData.data.sy000000_O_00VO
      console.log(t_data)

      // 계좌목록조회 초기화
      this.lv_acct_list = []
      // this.lv_ed_acct_gb.disabled = true

      if (t_data.length > 0) {
        t_data.forEach((item, idx) => {
          this.lv_acct_list.push({ value: idx, text: item.acct_no_nm, hiddenValue: item.bfcer_accn_no })
        })
        // 계좌목록조회 결과 첫번째 선택 및 활성화
        this.lv_ed_acct_gb.value = 0
        // this.lv_ed_acct_gb.disabled = false
        console.log('계좌선택 index @@@@@@@@@@@@@@@@@@@@@@@@@@@', this.lv_ed_acct_gb.value)
        // 고객정보조회
        this.fn_CustInfo()
      } else {
        let t_popupObj = { content: '검색할 계좌가 없습니다.' }
        this.fn_AlertPopup(0, t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_confirm
     * 설명       : 확인 버튼 클릭 이벤트
     ******************************************************************************/
    async fn_confirm() {
      if (!this.fn_confirmValidate()) return

      var nBtransAmt = this.mae_btrans_amt.value

      if (FSCommUtil.gfn_isNull(nBtransAmt)) nBtransAmt = ''

      nBtransAmt = Number(nBtransAmt)

      console.log('출금계좌 :' + this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue)
      console.log('인출가능금액계좌 :' + this.lv_acct_no)

      if (this.tel_use_yn != 'Y') {
        if (!this.isMobile) {
          if (this.passwd.value == '' || FSCommUtil.gfn_length(this.passwd.value) < 4) {
            FSCommUtil.gfn_validate(this, '필수입력 항목입니다.')
            this.$refs['passwd'].focus()
          }
        } else {
          if (this.lv_masked_val == '' || FSCommUtil.gfn_length(this.lv_masked_val) < 4) {
            FSCommUtil.gfn_validate(this, '필수입력 항목입니다.')
            this.$refs['passwd'].focus()
          }
        }
      }

      var bfcer_cust_no = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue.substr(0, 7)
      // 법조치 aml 재수행관련
      console.log('AML체크')

      let aml_check_data = await FSAmlUtil.gfn_aml_check(this, bfcer_cust_no, 'RP2010', '', this.lv_reCall_yn, this.lv_reObjRtn, this.fn_AlertPopup, this.fn_OpenPopup)
      if (this.lv_end_reCall_yn == 'Y') {
        let oArg = {
          cust_no: bfcer_cust_no,
          acct_no: this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue,
          re_aml_flag: 'Y'
        }
        this.fn_OpenPopup('MSPFS315P', oArg)
      } else {
        this.fn_confirmCallback()
      }

      console.log('AML체크끝 출금시')

      // await this.fn_confirmCallback();
      // if( !aml_check_data ){
      //   console.log('여기가 나중에')
      //   this.aml_yn = "N"
      //   this.btn_confirm = true
      //   return
      // }else{
      //   console.log('여기가 나중에22')
      //   console.log('test2')
      //
      // }
    },

    async fn_confirmCallback() {
      console.log('fn_confirmCallback...')
      var nAcctNo = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue
      var nBfcerAccnNo = this.lv_acct_no

      this.aml_yn = 'Y'

      console.log('AML체크 끝')

      if (nAcctNo != nBfcerAccnNo) {
        //인출가능금액 조회 계좌 확인
        console.log('확인버튼 11111', nAcctNo)
        console.log('확인버튼 11111', nBfcerAccnNo)
        let t_popupObj = {
          content: '인출가능금액 조회 계좌와 다른 계좌를 선택 하셨습니다.\n\n 다른 계좌를 선택 하실려면 새로고침 이후 다시 조회 하시기 바랍니다.'
        }
        this.fn_AlertPopup(0, t_popupObj)
        return
      }

      console.log('책임자승인 : application 주석처리 ') //+ application.gv_adm_seq_no);

      // 책임자 승인전에만 메시지 호출
      if (this.gv_adm_seq_no == '0') {
        let t_popupObj = {
          confirm: true,
          cancel: true,
          confirmFunc: this.fn_btransLimitAmtCheck, //은행이체금액 1회한도지정여부 확인
          content: '약정이체 출금하실 금액이 ' + this.$commonUtil.numberWithCommas(this.mae_btrans_amt.value) + '원 입니다.'
        }
        this.fn_AlertPopup(0, t_popupObj)
      } else {
        this.fn_btransLimitAmtCheck()
      }
    },

    /******************************************************************************
     * Function명 : fn_confirmValidate
     * 설명       : 확인 버튼 유효성 검사
     ******************************************************************************/
    fn_confirmValidate() {
      console.log('유효성 검사')
      let t_popupObj = {}
      console.log('확인버튼유효성검사@@@@@@@@@@@', this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue)
      var sAccNo = this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue // 계좌번호
      var sPasswd = this.passwd // 비밀번호
      var sRedemGb = this.redem_gb.value // 환매구분
      var nPayAmt = this.mae_paym_amt.value // 환매금액
      var nPaymPossAmt = this.paym_possamt.value //전액출금가능 금액
      var nPartPaymPossAmt = this.mae_part_paym_possamt.value //출금금액

      if (FSCommUtil.gfn_isNull(sAccNo)) {
        sAccNo = ''
      }
      if (FSCommUtil.gfn_isNull(sPasswd)) {
        sPasswd = ''
      }
      if (FSCommUtil.gfn_isNull(sRedemGb)) {
        sRedemGb = ''
      }
      if (FSCommUtil.gfn_isNull(nPayAmt)) {
        nPayAmt = ''
      }
      nPayAmt = Number(nPayAmt)

      if (FSCommUtil.gfn_isNull(nPaymPossAmt)) {
        nPaymPossAmt = ''
      }
      nPaymPossAmt = Number(nPaymPossAmt)

      if (FSCommUtil.gfn_isNull(nPartPaymPossAmt)) {
        nPartPaymPossAmt = ''
      }
      nPartPaymPossAmt = Number(nPartPaymPossAmt)

      console.log('확인버튼유효성검사2@@@@@@@@@@@', this.lv_acct_list[Number(this.lv_ed_acct_gb.value)].hiddenValue)

      var TOT = this.fn_getTotAmt()

      console.log('\t 환매구분 						  		: [' + sRedemGb + ']')
      console.log('\t 금액(좌수) 			nPayAmt 			: [' + nPayAmt + ']')
      console.log('\t 전액 출금가능금액 	nPaymPossAmt 		: [' + nPaymPossAmt + ']')
      console.log('\t 출금금액 			nPartPaymPossAmt 	: [' + nPartPaymPossAmt + ']')

      console.log('\t 송금금액 			nTotAmt 			: [' + TOT + ']')

      if (FSCommUtil.gfn_length(sAccNo) != 13) {
        FSCommUtil.gfn_validate(this, '계좌번호 입력오류입니다.')
        this.$refs['ed_accn_no'].focus()
        return false
      }

      if (FSCommUtil.gfn_length(sPasswd) < 4) {
        FSCommUtil.gfn_validate(this, '비밀번호를 다시 입력하십시오.')
        return false
      }

      if (sRedemGb != '11' && sRedemGb != '12' && sRedemGb != '13' && sRedemGb != '14' && sRedemGb != '15') {
        FSCommUtil.gfn_validate(this, '환매구분 오류입니다.')
        return false
      }

      if (sRedemGb != '11' && sRedemGb != '14') {
        if (nPayAmt <= 0) {
          FSCommUtil.gfn_validate(this, '금액(좌수)를 입력하세요.')
          return false
        }
      }
      console.log('유효성검사 1424')
      //송금금액
      var nTotAmt = this.fn_getTotAmt()

      if (nTotAmt <= 0) {
        FSCommUtil.gfn_validate(this, '송금금액 확인하십시오.')
        return false
      }

      if (sRedemGb == '11') {
        if (nPayAmt > 0 || nPayAmt < 0) {
          FSCommUtil.gfn_validate(this, '전액출금시 출금 금액(좌수)를 0으로 입력하십시오.')
          return false
        }
      } else if (sRedemGb == '12') {
        if (nPartPaymPossAmt != nTotAmt) {
          FSCommUtil.gfn_validate(this, '송금금액을 확인하십시오.')
          return false
        }
      } else if (sRedemGb == '13') {
        if (nPartPaymPossAmt > nPaymPossAmt) {
          FSCommUtil.gfn_validate(this, '인출가능금액이 부족 합니다.')
          return false
        }

        if (nPayAmt != nTotAmt) {
          console.log('1. 출금금액 X', nPayAmt)
          console.log('출금금액 X', nTotAmt)
          FSCommUtil.gfn_validate(this, '출금금액을 확인하십시오.')
          return false
        }

        if (nPartPaymPossAmt != nTotAmt) {
          console.log('2. 출금금액 X', nTotAmt)
          console.log('출금금액 X', nPartPaymPossAmt)
          FSCommUtil.gfn_validate(this, '출금금액을 확인하십시오.')
          return false
        }
      } else if (sRedemGb == '14') {
        if (nPayAmt > 0 || nPayAmt < 0) {
          FSCommUtil.gfn_validate(this, '수익금 전액은 출금 금액(좌수)를 0으로 입력하십시오.')
          return false
        }
      }
      console.log('입력값체크 end')

      //은행이체/약정계좌지급방법 추가로 인한 추가 검증로직
      //약정이체
      if (FSCommUtil.gfn_isNull(this.bank_cd.value)) {
        FSCommUtil.gfn_validate(this, '약정계좌번호를 선택하세요.')
        //todo... error
        // this.$refs['bank_cd'].focus();
        return false
      }

      console.log('입력값체크333333')

      var nPensionNotPaymTotAmt = this.mae_pension_not_paym_tot_amt //연금환매 미이체금액 전

      if (FSCommUtil.gfn_isNull(nPensionNotPaymTotAmt)) {
        nPensionNotPaymTotAmt = ''
      }
      nPensionNotPaymTotAmt = Number(nPensionNotPaymTotAmt)

      // 신연금 미이체금액이상금액을 이체할수 있습니다. 	김재연 2013-06-21
      if (nPayAmt != nPensionNotPaymTotAmt && nPensionNotPaymTotAmt > 0) {
        t_popupObj = { content: '(신)연금 미이체금액(' + this.mae_pension_not_paym_tot_amt + '원)이 존재합니다. 해당 금액부터 이체요청하시기 바랍니다.' }
        this.fn_AlertPopup(0, t_popupObj)
        return false
      }

      console.log('입력값체크44444')
      return true
    },

    /******************************************************************************
     * Function명 : fn_getTotAmt
     * 설명       : 금액확인
     ******************************************************************************/
    //송금금액
    fn_getTotAmt() {
      console.log('금액확인(송금)')
      var nCashPaymAmt = this.mae_btrans_amt.value
      if (FSCommUtil.gfn_isNull(nCashPaymAmt)) {
        nCashPaymAmt = ''
      }
      nCashPaymAmt = Number(nCashPaymAmt)

      if (FSCommUtil.gfn_isNull(nCashPaymAmt)) {
        nCashPaymAmt = 0
      }

      console.log('금액확인(송금) : ' + nCashPaymAmt)
      return nCashPaymAmt
    },
    /******************************************************************************
     * Function명 : fn_Acct_no_call
     * 설명       : 계좌번호 변경시
     ******************************************************************************/
    fn_Acct_no_call(e) {
      console.log('계좌번호변경', e)

      //보안키패드
      this.lv_isClear = !this.lv_isClear ? true : false
      this.lv_masked_val = ''

      //비밀번호 체크 여부
      this.gArs_pswn_yn = ''
      // this.div_ars_gb.fn_init( this );		/* 초기화 */

      //
      this.fn_initAcc()

      var nIndex

      console.log('this.lv_acct_list', this.lv_acct_list)
      if (this.lv_acct_list.length > 0) {
        // 20230504  sCallType 확인중
        //폰 패드/ 핀 패드 비밀번호 입력시 인출 가능 금액 자동 조회 후 Call
        // if (sCallType == "REDEM_IMPOSI_SEARCH" ) {
        //   this.mae_paym_amt.value = "";
        //   this.redem_gb.value = "";
        // }

        //약정이체이면 계좌번호에 대한 약정계좌번호 조회
        //약정계좌조회
        this.fn_searchBTransAcctNo()

        //ASR161100294_연금통장 입금액 출금 가능 로직 추가 by 2016.12.13 김진규
        //연금통장 일 시 과세발생 가능성 안내팝업 추가

        var temp = ''
        this.lv_acct_list.forEach((item, i) => {
          if (i == this.lv_ed_acct_gb.value) {
            console.log('forEach value check', item)
            temp = item.pbk_tax_tp_gb
          }
        })
        if (
          temp == '13'
          // 확인중 todo.. // && sCallType != "REDEM_IMPOSI_SEARCH"
        ) {
          var sMessage = '해당계좌는 연금통장 계좌입니다.\n'
          sMessage += '출금예상금액은 연금세금공제전 금액으로 실제 출금금액과 상이 할 수 있습니다.'
          t_popupObj = { content: sMessage }
          this.fn_AlertPopup(0, t_popupObj)
        }
      } else {
        this.paym_possamt.value = ''
        this.paym_possqty.value = ''
        console.log('전액출금가능좌수', this.paym_possqty.value)
        this.mae_part_paym_possamt = ''
      }

      //비밀번호 초기화
      this.passwd.value = ''
      this.lv_masked_val = ''
    },

    /******************************************************************************
     * Function명 : fn_CustInfo, fn_SetEdCustName
     * 설명       : 고객정보조회
     ******************************************************************************/
    fn_CustInfo() {
      console.log('fn_CustInfo.....pCustInfoObj', this.pCustInfoObj)
      this.pCustInfoObj.custNo = FSCommUtil.gfn_substr(this.lv_ed_accn_no.value, 0, 7)
      this.$refs.fsCustInfo.fn_CustInfoSearch()
      //비밀번호 입력 활성화
      this.passwd.disabled = false
      this.fn_divSearchAccnNoList_CallBack()
    },

    fn_SetEdCustName(val) {
      // 고객명
      this.lv_ed_cust_nm.value = val

      // 고객번호 설정 (*이탈 시 커버발행 설정 목적)
      // this.$bizUtil.fsUtils.setCustNoForCvrBpl(this, val, this.lv_ed_accn_no.value)
      // 고객용 정보조회
      this.$bizUtil.fsUtils.selCustInfo(this, { bfcer_cust_no: this.lv_ed_accn_no.value })

      if (this.lv_ed_accn_no.value == '' || this.ds_search.rlnm_cnf_no_encr != '') {
        this.ds_search.fnd_cust_nm = val
      }
    },

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(type, pPopupObj) {
      if (!FSCommUtil.gfn_isNull(pPopupObj)) {
        // 공통 얼럿 팝업 오브젝트 초기화
        FSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    /******************************************************************************
       * Function명 : fn_OpenPopup
       * 설명       : popup 컴포넌트 호출
       * 타입(type)
          MSPFS301P: AML_실제소유자여부확인
          MSPFS305P: 고위험승인요청
          MSPFS315P: 자금세탁(AML) 고객확인 재이행
       ******************************************************************************/
    fn_OpenPopup(type, pData) {
      switch (type) {
        case 0:
          this.$refs.popupObj.fn_Open()
          break

        case 'MSPFS301P':
          // AML_실제소유자여부확인
          this.pPopup301Obj = pData
          this.$refs.popup301.fn_Open()
          break

        case 'MSPFS305P':
          // 고위험승인요청
          this.pPopup305Obj = pData
          this.$refs.popup305.fn_Open()
          break

        case 'MSPFS313P':
          this.pPopup313Obj = pData
          this.$refs.popup313.fn_Open()
          break

        case 'MSPFS315P':
          // 자금세탁(AML) 고객확인 재이행
          this.pPopup315Obj = pData
          this.$refs.popup315.fn_Open()
          break

        case 'MSPFS320P':
          // 책임자승인
          this.pPopup320Obj = pData
          this.$refs.popup320.fn_Open()
          break

        case 'MSPFS324P':
          // 금감원
          this.pPopup324Obj = pData
          this.$refs.popup324.fn_Open()
          break
      }
    },
    /******************************************************************************
       * Function명 : fn_Popup_CallBack
       * 설명       : popup 컴포넌트에서 callback
       * 타입(type) :
          0: MSPFS313P
          MSPFS301P: AML_실제소유자여부확인
          MSPFS305P: 고위험승인요청
          MSPFS315P: 자금세탁(AML) 고객확인 재이행
       ******************************************************************************/
    fn_Popup_CallBack(type, pData, supId) {
      console.log(type, pData, supId)
      let t_popupObj = {}

      switch (type) {
        case 'MSPFS313P':
          console.log('313p callback...')
          break

        case 'MSPFS301P':
          //console.log('AML실제소유자여부 callback...')

          // 고객번호 매핑 후 조회
          this.lv_reObjRtn = pData
          console.log(this.lv_reObjRtn)

          //this.lv_ed_cust_no.value = pData.cust_no
          //this.fn_SearchValid({key: 'v'}, 0)
          break

        case 'MSPFS305P':
          console.log('고위험승인요청 callback...')
          // 2024.06.18 ASR240600395 고위험승인내역 화면 개선 요청
          this.lv_ed_accn_no.value = pData.acct_no
          this.fn_SearchValid({ key: 'v' })
          break

        case 'MSPFS315P':
          console.log('자금세탁(AML) 고객확인 재이행 callback...', type, pData, supId)

          // 재이행 2번째 팝업일때
          if (this.lv_end_reCall_yn == 'Y') {
            if (pData.real_owner_gb != 'Y') {
              return
            } else {
              this.fn_confirmCallback()
            }
          } else {
            // 재이행 1번째 팝업일때

            // 고객번호 매핑 후 조회
            this.lv_reObjRtn = pData

            this.lv_ed_accn_no.value = pData.acct_no
            this.lv_reCall_yn = 'Y'

            // 재이행 팝업 확인
            this.lv_end_reCall_yn = 'Y'

            if (this.aml_yn == 'N') {
              this.fn_SearchValid({ key: 'v' })
            }
          }

          break

        case 'MSPFS324P':
          console.log('금감원 callback...')
          break
      }
    },

    fn_Popup_CallBack320(res) {
      console.log('책임자승인 callback...', res)
      if (res.successYN == 'Y') {
        this.gv_adm_seq_no = res.gv_adm_seq_no
        this.gv_adm_eno = res.gv_adm_eno
        this.fn_confirm()
      } else {
        return
      }
    },
    /******************************************************************************
     * Function명 : fn_GoAgentRegist
     * 설명       : 업무대리인등록/변경
     ******************************************************************************/
    fn_GoAgentRegist() {
      this.$router.push({
        name: 'MSPFS302M',
        params: this.bfcer_cust_no //{cob_rname_gb: this.lv_cob_rname_gb.value, ed_rname_no: [this.lv_ed_rname_no.value1, this.lv_ed_rname_no.value2]}
      })
    },

    /******************************************************************************
     * Function명 : fn_SetCommCode, fn_SetCommCodeResult
     * 설명       : 공통코드 세팅
     ******************************************************************************/
    fn_SetCommCode() {
      console.log('fn_SetCommCode...')

      let params = [
        { cId: '322', dayChkYn: 'N' },
        { cId: '989', dayChkYn: 'N' }
      ]
      FSCodeUtil.commCodeList(this, params, this.fn_SetCommCodeResult)
    },

    fn_SetCommCodeResult(pResult) {
      this.lv_commCode = pResult

      let t_codeList = []
      pResult.code322.forEach((item, idx) => {
        if (item.value == '11' || item.value == '13') {
          t_codeList.push(item)
        }
      })

      // 환매구분 list
      this.lv_commCode.code322 = t_codeList
      this.lv_cbo_redem_gb_items = this.lv_commCode.code322
    },

    /******************************************************************************
     * Function명 : fn_historyBack
     * 설명       : 뒤로가기 버튼
     ******************************************************************************/
    fn_HistoryBack() {
      this.$router.go(-1)
    },

    validate() {
      this.$addSnackbar({ text: '필수입력 항목입니다.', error: true })
    },

    /******************************************************************************
     * Function명 : fn_IsActive
     * 설명       : 고객정보 펼치기/접기
     ******************************************************************************/
    fn_IsActive() {
      this.isActive = !this.isActive
    },

    /******************************************************************************
     * Function명 : fn_IsActive
     * 설명       : 고객정보 펼치기/접기
     ******************************************************************************/
    fn_search_fss() {
      let t_obj = {
        event_no: this.lv_cust_fss.fssInfoExpsrEvntNo,
        event_cust_nm: this.lv_cust_fss.custNm,
        event_reg_no: FSCommUtil.gfn_substr(this.lv_cust_fss.custRrn, 0, 6) + '-' + FSCommUtil.gfn_substr(this.lv_cust_fss.custRrn, 6, 1) + '******',
        tel_no: this.lv_cust_fss.telno,
        hp_no: this.lv_cust_fss.celno,
        sago_cd: this.lv_cust_fss.fssInfoExpsrAcdtCd,
        sago_txt: this.lv_cust_fss.fssInfoExpsrAcdtNm,
        apnd_cmpy: this.lv_cust_fss.fssInfoExpsrRegCoNm,
        apnd_ymd: this.lv_cust_fss.regDtm
      }

      this.fn_OpenPopup('MSPFS324P', t_obj)
    }
  }
}
</script>
<style scoped></style>
